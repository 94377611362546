import AOS from 'aos';

const initAOS = () => {
	AOS.init({
		duration: 700,
		once: true
	});
}

export default function animationsUI() {
	initAOS();
}
