const toggleModal = () => {
	const triggers = document.querySelectorAll('.js-modal-toggle')

	triggers.forEach(trigger => {
		const targetSelector = trigger.getAttribute('href') || trigger.getAttribute('data-target');
		const target = document.querySelector(targetSelector);

		trigger.addEventListener('click', e => {
			e.preventDefault();

			target.classList.toggle('active')
		})
	})
}

export default function modalsUI() {
	toggleModal();
}
