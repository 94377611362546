const replayVideo = () => {
	const containers = document.querySelectorAll('.js-replay');

	containers.forEach(container => {
		const video = container.querySelector('.js-replay-video');
		const btn = container.querySelector('.js-replay-btn');

		const showBtn = () => {
			btn.classList.add('active');
		}

		video.addEventListener('ended', showBtn, false);

		btn.addEventListener('click', function(e) {
			video.currentTime = '0';

			video.play();
		});
	})
}

export default function videosUI() {
	replayVideo();
}
