import Swiper from 'swiper';

function removeClass(elements, className) {
	elements.forEach(el => {
		el.classList.remove(className)
	})
}

const initSliderFeatures = () => {
	const containers = document.querySelectorAll('.js-features')

	if (containers.length) {
		containers.forEach(container => {
			const slider = container.querySelector('.swiper-container');
			const triggers = container.querySelectorAll('.js-feature-trigger')

			const swiper = new Swiper (slider, {
				loop: true,
				loopedSlides: 0,
				speed: 300,
				slidesPerView: 1,
				simulateTouch: false,
				effect: 'fade',
				fadeEffect: {
					crossFade: true,
				},
				autoplay: {
					delay: 7500
				},
				preloadImages: true,
				lazy: {
					loadPrevNext: true,
					loadPrevNextAmount: 1,
				},
				on: {
					slideChange() {
						removeClass(triggers, 'active');

						triggers[swiper.realIndex].classList.add('active');
					}
				}
			});


			triggers.forEach((trigger, index) => {
				trigger.addEventListener('click', e => {
					e.preventDefault();

					swiper.autoplay.stop();
					swiper.slideTo(index+1);
				});
			})
		})
	}
}

export default function slidersUI() {
	initSliderFeatures();
}
