const initPageLoader = () => {
	const wrapper = document.querySelector('.wrapper');
	const loader = document.querySelector('.loader');
	const video = document.querySelector('.js-video-autoplay')

	if (loader) {
		document.onreadystatechange = function() {
			if (document.readyState !== 'complete') {
				loader.classList.add('active')
			} else {
				loader.classList.remove('active')

				if (video) {
					video.play();
				}
			}
		};
	}
}

export default function pageLoadersUI() {
	initPageLoader();
}
