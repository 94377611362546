const showCurrentYear = selector => {
	const elements = document.querySelectorAll(selector)
	const year = new Date().getFullYear()

	elements.forEach(el => {
		el.innerText = year
	})
}

export default function currentYear() {
	showCurrentYear('.js-current-year')
}
