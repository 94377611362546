import currentYear from './current-year';
import slidersUI from './sliders-ui';
import modalsUI from './modals-ui';
import videosUI from './videos-ui';
import lazyLoadingUI from './lazy-loading-ui';
import animationsUI from './animations-ui';
import pageLoadersUI from './page-loaders-ui';

(function() {

	currentYear();

	slidersUI();

	videosUI();

	// modalsUI();

	lazyLoadingUI();

	animationsUI();

	pageLoadersUI();

})(window, document)
