import 'lazysizes';

const initLazyLoad = () => {
	window.lazySizes.cfg.expFactor = 2;

	document.addEventListener('lazybeforeunveil', function(e){
		const el = e.target;
		const bg = el.getAttribute('data-src');

		el.setAttribute('src', bg)
	});
}

export default function lazyLoadingUI() {
	initLazyLoad();
}
